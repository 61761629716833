"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeInternal = exports.GRPCWrapper = exports.OasisCodedError = void 0;
const grpcWeb = require("grpc-web");
const proto = require("../proto");
const misc = require("./misc");
function toCBOR(v) {
    // gRPC cannot handle nil arguments unmarshalled from CBOR, so we use a special case to
    // marshal `nil` to an empty byte string.
    if (v == null)
        return new Uint8Array();
    return misc.toCBOR(v);
}
function createMethodDescriptorUnary(serviceName, methodName) {
    const MethodType = grpcWeb.MethodType;
    return new grpcWeb.MethodDescriptor(`/oasis-core.${serviceName}/${methodName}`, MethodType.UNARY, null, null, toCBOR, misc.fromCBOR);
}
function createMethodDescriptorServerStreaming(serviceName, methodName) {
    const MethodType = grpcWeb.MethodType;
    return new grpcWeb.MethodDescriptor(`/oasis-core.${serviceName}/${methodName}`, MethodType.SERVER_STREAMING, null, null, toCBOR, misc.fromCBOR);
}
// beacon
const methodDescriptorBeaconGetBaseEpoch = createMethodDescriptorUnary('Beacon', 'GetBaseEpoch');
const methodDescriptorBeaconGetEpoch = createMethodDescriptorUnary('Beacon', 'GetEpoch');
const methodDescriptorBeaconGetFutureEpoch = createMethodDescriptorUnary('Beacon', 'GetFutureEpoch');
const methodDescriptorBeaconWaitEpoch = createMethodDescriptorUnary('Beacon', 'WaitEpoch');
const methodDescriptorBeaconGetEpochBlock = createMethodDescriptorUnary('Beacon', 'GetEpochBlock');
const methodDescriptorBeaconGetBeacon = createMethodDescriptorUnary('Beacon', 'GetBeacon');
const methodDescriptorBeaconStateToGenesis = createMethodDescriptorUnary('Beacon', 'StateToGenesis');
const methodDescriptorBeaconConsensusParameters = createMethodDescriptorUnary('Beacon', 'ConsensusParameters');
const methodDescriptorBeaconWatchEpochs = createMethodDescriptorServerStreaming('Beacon', 'WatchEpochs');
// scheduler
const methodDescriptorSchedulerGetValidators = createMethodDescriptorUnary('Scheduler', 'GetValidators');
const methodDescriptorSchedulerGetCommittees = createMethodDescriptorUnary('Scheduler', 'GetCommittees');
const methodDescriptorSchedulerStateToGenesis = createMethodDescriptorUnary('Scheduler', 'StateToGenesis');
const methodDescriptorSchedulerConsensusParameters = createMethodDescriptorUnary('Scheduler', 'ConsensusParameters');
const methodDescriptorSchedulerWatchCommittees = createMethodDescriptorServerStreaming('Scheduler', 'WatchCommittees');
// registry
const methodDescriptorRegistryGetEntity = createMethodDescriptorUnary('Registry', 'GetEntity');
const methodDescriptorRegistryGetEntities = createMethodDescriptorUnary('Registry', 'GetEntities');
const methodDescriptorRegistryGetNode = createMethodDescriptorUnary('Registry', 'GetNode');
const methodDescriptorRegistryGetNodeByConsensusAddress = createMethodDescriptorUnary('Registry', 'GetNodeByConsensusAddress');
const methodDescriptorRegistryGetNodeStatus = createMethodDescriptorUnary('Registry', 'GetNodeStatus');
const methodDescriptorRegistryGetNodes = createMethodDescriptorUnary('Registry', 'GetNodes');
const methodDescriptorRegistryGetRuntime = createMethodDescriptorUnary('Registry', 'GetRuntime');
const methodDescriptorRegistryGetRuntimes = createMethodDescriptorUnary('Registry', 'GetRuntimes');
const methodDescriptorRegistryStateToGenesis = createMethodDescriptorUnary('Registry', 'StateToGenesis');
const methodDescriptorRegistryGetEvents = createMethodDescriptorUnary('Registry', 'GetEvents');
const methodDescriptorRegistryWatchEntities = createMethodDescriptorServerStreaming('Registry', 'WatchEntities');
const methodDescriptorRegistryWatchNodes = createMethodDescriptorServerStreaming('Registry', 'WatchNodes');
const methodDescriptorRegistryWatchNodeList = createMethodDescriptorServerStreaming('Registry', 'WatchNodeList');
const methodDescriptorRegistryWatchRuntimes = createMethodDescriptorServerStreaming('Registry', 'WatchRuntimes');
// staking
const methodDescriptorStakingTokenSymbol = createMethodDescriptorUnary('Staking', 'TokenSymbol');
const methodDescriptorStakingTokenValueExponent = createMethodDescriptorUnary('Staking', 'TokenValueExponent');
const methodDescriptorStakingTotalSupply = createMethodDescriptorUnary('Staking', 'TotalSupply');
const methodDescriptorStakingCommonPool = createMethodDescriptorUnary('Staking', 'CommonPool');
const methodDescriptorStakingLastBlockFees = createMethodDescriptorUnary('Staking', 'LastBlockFees');
const methodDescriptorStakingGovernanceDeposits = createMethodDescriptorUnary('Staking', 'GovernanceDeposits');
const methodDescriptorStakingThreshold = createMethodDescriptorUnary('Staking', 'Threshold');
const methodDescriptorStakingAddresses = createMethodDescriptorUnary('Staking', 'Addresses');
const methodDescriptorStakingAccount = createMethodDescriptorUnary('Staking', 'Account');
const methodDescriptorStakingDelegationsFor = createMethodDescriptorUnary('Staking', 'DelegationsFor');
const methodDescriptorStakingDelegationInfosFor = createMethodDescriptorUnary('Staking', 'DelegationInfosFor');
const methodDescriptorStakingDelegationsTo = createMethodDescriptorUnary('Staking', 'DelegationsTo');
const methodDescriptorStakingDebondingDelegationsFor = createMethodDescriptorUnary('Staking', 'DebondingDelegationsFor');
const methodDescriptorStakingDebondingDelegationInfosFor = createMethodDescriptorUnary('Staking', 'DebondingDelegationInfosFor');
const methodDescriptorStakingDebondingDelegationsTo = createMethodDescriptorUnary('Staking', 'DebondingDelegationsTo');
const methodDescriptorStakingAllowance = createMethodDescriptorUnary('Staking', 'Allowance');
const methodDescriptorStakingStateToGenesis = createMethodDescriptorUnary('Staking', 'StateToGenesis');
const methodDescriptorStakingConsensusParameters = createMethodDescriptorUnary('Staking', 'ConsensusParameters');
const methodDescriptorStakingGetEvents = createMethodDescriptorUnary('Staking', 'GetEvents');
const methodDescriptorStakingWatchEvents = createMethodDescriptorServerStreaming('Staking', 'WatchEvents');
// keymanager
const methodDescriptorKeyManagerGetStatus = createMethodDescriptorUnary('KeyManager', 'GetStatus');
const methodDescriptorKeyManagerGetStatuses = createMethodDescriptorUnary('KeyManager', 'GetStatuses');
// roothash
const methodDescriptorRootHashGetGenesisBlock = createMethodDescriptorUnary('RootHash', 'GetGenesisBlock');
const methodDescriptorRootHashGetLatestBlock = createMethodDescriptorUnary('RootHash', 'GetLatestBlock');
const methodDescriptorRootHashGetRuntimeState = createMethodDescriptorUnary('RootHash', 'GetRuntimeState');
const methodDescriptorRootHashGetLastRoundResults = createMethodDescriptorUnary('RootHash', 'GetLastRoundResults');
const methodDescriptorRootHashGetIncomingMessageQueueMeta = createMethodDescriptorUnary('RootHash', 'GetIncomingMessageQueueMeta');
const methodDescriptorRootHashGetIncomingMessageQueue = createMethodDescriptorUnary('RootHash', 'GetIncomingMessageQueue');
const methodDescriptorRootHashStateToGenesis = createMethodDescriptorUnary('RootHash', 'StateToGenesis');
const methodDescriptorRootHashConsensusParameters = createMethodDescriptorUnary('RootHash', 'ConsensusParameters');
const methodDescriptorRootHashGetEvents = createMethodDescriptorUnary('RootHash', 'GetEvents');
const methodDescriptorRootHashWatchBlocks = createMethodDescriptorServerStreaming('RootHash', 'WatchBlocks');
const methodDescriptorRootHashWatchEvents = createMethodDescriptorServerStreaming('RootHash', 'WatchEvents');
// governance
const methodDescriptorGovernanceActiveProposals = createMethodDescriptorUnary('Governance', 'ActiveProposals');
const methodDescriptorGovernanceProposals = createMethodDescriptorUnary('Governance', 'Proposals');
const methodDescriptorGovernanceProposal = createMethodDescriptorUnary('Governance', 'Proposal');
const methodDescriptorGovernanceVotes = createMethodDescriptorUnary('Governance', 'Votes');
const methodDescriptorGovernancePendingUpgrades = createMethodDescriptorUnary('Governance', 'PendingUpgrades');
const methodDescriptorGovernanceStateToGenesis = createMethodDescriptorUnary('Governance', 'StateToGenesis');
const methodDescriptorGovernanceConsensusParameters = createMethodDescriptorUnary('Governance', 'ConsensusParameters');
const methodDescriptorGovernanceGetEvents = createMethodDescriptorUnary('Governance', 'GetEvents');
const methodDescriptorGovernanceWatchEvents = createMethodDescriptorServerStreaming('Governance', 'WatchEvents');
// storage
const methodDescriptorStorageSyncGet = createMethodDescriptorUnary('Storage', 'SyncGet');
const methodDescriptorStorageSyncGetPrefixes = createMethodDescriptorUnary('Storage', 'SyncGetPrefixes');
const methodDescriptorStorageSyncIterate = createMethodDescriptorUnary('Storage', 'SyncIterate');
const methodDescriptorStorageGetCheckpoints = createMethodDescriptorUnary('Storage', 'GetCheckpoints');
const methodDescriptorStorageGetDiff = createMethodDescriptorServerStreaming('Storage', 'GetDiff');
const methodDescriptorStorageGetCheckpointChunk = createMethodDescriptorServerStreaming('Storage', 'GetCheckpointChunk');
// worker/storage
const methodDescriptorStorageWorkerGetLastSyncedRound = createMethodDescriptorUnary('StorageWorker', 'GetLastSyncedRound');
const methodDescriptorStorageWorkerWaitForRound = createMethodDescriptorUnary('StorageWorker', 'WaitForRound');
const methodDescriptorStorageWorkerPauseCheckpointer = createMethodDescriptorUnary('StorageWorker', 'PauseCheckpointer');
// runtime/client
const methodDescriptorRuntimeClientSubmitTx = createMethodDescriptorUnary('RuntimeClient', 'SubmitTx');
const methodDescriptorRuntimeClientSubmitTxMeta = createMethodDescriptorUnary('RuntimeClient', 'SubmitTxMeta');
const methodDescriptorRuntimeClientSubmitTxNoWait = createMethodDescriptorUnary('RuntimeClient', 'SubmitTxNoWait');
const methodDescriptorRuntimeClientCheckTx = createMethodDescriptorUnary('RuntimeClient', 'CheckTx');
const methodDescriptorRuntimeClientGetGenesisBlock = createMethodDescriptorUnary('RuntimeClient', 'GetGenesisBlock');
const methodDescriptorRuntimeClientGetBlock = createMethodDescriptorUnary('RuntimeClient', 'GetBlock');
const methodDescriptorRuntimeClientGetLastRetainedBlock = createMethodDescriptorUnary('RuntimeClient', 'GetLastRetainedBlock');
const methodDescriptorRuntimeClientGetTransactions = createMethodDescriptorUnary('RuntimeClient', 'GetTransactions');
const methodDescriptorRuntimeClientGetTransactionsWithResults = createMethodDescriptorUnary('RuntimeClient', 'GetTransactionsWithResults');
const methodDescriptorRuntimeClientGetEvents = createMethodDescriptorUnary('RuntimeClient', 'GetEvents');
const methodDescriptorRuntimeClientQuery = createMethodDescriptorUnary('RuntimeClient', 'Query');
const methodDescriptorRuntimeClientWatchBlocks = createMethodDescriptorServerStreaming('RuntimeClient', 'WatchBlocks');
// consensus
const methodDescriptorConsensusSubmitTx = createMethodDescriptorUnary('Consensus', 'SubmitTx');
const methodDescriptorConsensusStateToGenesis = createMethodDescriptorUnary('Consensus', 'StateToGenesis');
const methodDescriptorConsensusEstimateGas = createMethodDescriptorUnary('Consensus', 'EstimateGas');
const methodDescriptorConsensusGetSignerNonce = createMethodDescriptorUnary('Consensus', 'GetSignerNonce');
const methodDescriptorConsensusGetBlock = createMethodDescriptorUnary('Consensus', 'GetBlock');
const methodDescriptorConsensusGetTransactions = createMethodDescriptorUnary('Consensus', 'GetTransactions');
const methodDescriptorConsensusGetTransactionsWithResults = createMethodDescriptorUnary('Consensus', 'GetTransactionsWithResults');
const methodDescriptorConsensusGetUnconfirmedTransactions = createMethodDescriptorUnary('Consensus', 'GetUnconfirmedTransactions');
const methodDescriptorConsensusGetGenesisDocument = createMethodDescriptorUnary('Consensus', 'GetGenesisDocument');
const methodDescriptorConsensusGetChainContext = createMethodDescriptorUnary('Consensus', 'GetChainContext');
const methodDescriptorConsensusGetStatus = createMethodDescriptorUnary('Consensus', 'GetStatus');
const methodDescriptorConsensusGetNextBlockState = createMethodDescriptorUnary('Consensus', 'GetNextBlockState');
const methodDescriptorConsensusWatchBlocks = createMethodDescriptorServerStreaming('Consensus', 'WatchBlocks');
const methodDescriptorConsensusLightGetLightBlock = createMethodDescriptorUnary('ConsensusLight', 'GetLightBlock');
const methodDescriptorConsensusLightGetParameters = createMethodDescriptorUnary('ConsensusLight', 'GetParameters');
const methodDescriptorConsensusLightStateSyncGet = createMethodDescriptorUnary('ConsensusLight', 'StateSyncGet');
const methodDescriptorConsensusLightStateSyncGetPrefixes = createMethodDescriptorUnary('ConsensusLight', 'StateSyncGetPrefixes');
const methodDescriptorConsensusLightStateSyncIterate = createMethodDescriptorUnary('ConsensusLight', 'StateSyncIterate');
const methodDescriptorConsensusLightSubmitTxNoWait = createMethodDescriptorUnary('ConsensusLight', 'SubmitTxNoWait');
const methodDescriptorConsensusLightSubmitEvidence = createMethodDescriptorUnary('ConsensusLight', 'SubmitEvidence');
// control
const methodDescriptorNodeControllerRequestShutdown = createMethodDescriptorUnary('NodeController', 'RequestShutdown');
const methodDescriptorNodeControllerWaitSync = createMethodDescriptorUnary('NodeController', 'WaitSync');
const methodDescriptorNodeControllerIsSynced = createMethodDescriptorUnary('NodeController', 'IsSynced');
const methodDescriptorNodeControllerWaitReady = createMethodDescriptorUnary('NodeController', 'WaitReady');
const methodDescriptorNodeControllerIsReady = createMethodDescriptorUnary('NodeController', 'IsReady');
const methodDescriptorNodeControllerUpgradeBinary = createMethodDescriptorUnary('NodeController', 'UpgradeBinary');
const methodDescriptorNodeControllerCancelUpgrade = createMethodDescriptorUnary('NodeController', 'CancelUpgrade');
const methodDescriptorNodeControllerGetStatus = createMethodDescriptorUnary('NodeController', 'GetStatus');
const methodDescriptorDebugControllerSetEpoch = createMethodDescriptorUnary('DebugController', 'SetEpoch');
const methodDescriptorDebugControllerWaitNodesRegistered = createMethodDescriptorUnary('DebugController', 'WaitNodesRegistered');
class OasisCodedError extends Error {
}
exports.OasisCodedError = OasisCodedError;
class GRPCWrapper {
    constructor(base) {
        this.client = new grpcWeb.GrpcWebClientBase({});
        this.base = base;
    }
    callUnary(desc, request) {
        // @ts-expect-error missing declaration
        const name = desc.name;
        const method = this.base + name;
        // Some browsers with enormous market share aren't able to preserve the stack between here
        // and our `.catch` callback below. Save a copy explicitly.
        const invocationStack = new Error().stack;
        return this.client
            .thenableCall(method, request, 
        // @ts-expect-error metadata nullability not modeled
        null, desc)
            .catch((e) => {
            if (e.message === 'Incomplete response') {
                // This seems to be normal. Void methods don't send back anything, which makes
                // grpc-web freak out. I don't know why we don't send a CBOR undefined or
                // something.
                // TODO: remove after https://github.com/grpc/grpc-web/pull/1230
                //       and see TODO in methodDescriptorConsensusGetSignerNonce
                return undefined;
            }
            if (e.metadata?.['grpc-status-details-bin']) {
                const statusU8 = misc.fromBase64(e.metadata['grpc-status-details-bin']);
                const status = proto.google.rpc.Status.decode(statusU8);
                const details = status.details;
                // `errorFromGrpc` from oasis-core checks for exactly one entry in Details.
                // We additionally check that the type URL is empty, consistent with how
                // `errorToGrpc` leaves it blank.
                if (details.length === 1 && details[0].type_url === '' && details[0].value) {
                    const grpcError = misc.fromCBOR(details[0].value);
                    const innerMessage = e.message ||
                        `Message missing, module=${grpcError.module} code=${grpcError.code}`;
                    const message = `callUnary method ${method}: ${innerMessage}`;
                    // @ts-expect-error options and cause not modeled
                    const wrapped = new OasisCodedError(message, { cause: e });
                    wrapped.oasisCode = grpcError.code;
                    wrapped.oasisModule = grpcError.module;
                    wrapped.stack += `
Cause stack:
${e.stack}
End of cause stack
Invocation stack:
${invocationStack}
End of invocation stack`;
                    throw wrapped;
                }
            }
            // Just in case there's some non-Error rejection reason that doesn't come with metadata
            // from oasis-core as expected above, try using JSON to stringify it so that we don't
            // end up with [object Object].
            const innerMessage = e instanceof Error ? e.toString() : JSON.stringify(e);
            const message = `callUnary method ${method}: ${innerMessage}`;
            // @ts-expect-error options and cause not modeled
            const wrapped = new Error(message, { cause: e });
            wrapped.stack += `
Cause stack:
${e.stack}
End of cause stack
Invocation stack:
${invocationStack}
End of invocation stack`;
            throw wrapped;
        });
    }
    callServerStreaming(desc, request) {
        // @ts-expect-error missing declaration
        const name = desc.name;
        return this.client.serverStreaming(this.base + name, request, 
        // @ts-expect-error metadata nullability not modeled
        null, desc);
    }
}
exports.GRPCWrapper = GRPCWrapper;
class NodeInternal extends GRPCWrapper {
    constructor(base) {
        super(base);
    }
    // beacon
    /**
     * GetBaseEpoch returns the base epoch.
     */
    beaconGetBaseEpoch() {
        return this.callUnary(methodDescriptorBeaconGetBaseEpoch, undefined);
    }
    /**
     * GetEpoch returns the epoch number at the specified block height.
     * Calling this method with height `consensus.HeightLatest`, should
     * return the epoch of latest known block.
     */
    beaconGetEpoch(height) {
        return this.callUnary(methodDescriptorBeaconGetEpoch, height);
    }
    /**
     * GetFutureEpoch returns any future epoch that is currently scheduled
     * to occur at a specific height.
     *
     * Note that this may return a nil state in case no future epoch is
     * currently scheduled.
     */
    beaconGetFutureEpoch(height) {
        return this.callUnary(methodDescriptorBeaconGetFutureEpoch, height);
    }
    /**
     * WaitEpoch waits for a specific epoch.
     *
     * Note that an epoch is considered reached even if any epoch greater
     * than the one specified is reached (e.g., that the current epoch
     * is already in the future).
     */
    beaconWaitEpoch(epoch) {
        return this.callUnary(methodDescriptorBeaconWaitEpoch, epoch);
    }
    /**
     * GetEpochBlock returns the block height at the start of the said
     * epoch.
     */
    beaconGetEpochBlock(epoch) {
        return this.callUnary(methodDescriptorBeaconGetEpochBlock, epoch);
    }
    /**
     * GetBeacon gets the beacon for the provided block height.
     * Calling this method with height `consensus.HeightLatest` should
     * return the beacon for the latest finalized block.
     */
    beaconGetBeacon(height) {
        return this.callUnary(methodDescriptorBeaconGetBeacon, height);
    }
    /**
     * StateToGenesis returns the genesis state at specified block height.
     */
    beaconStateToGenesis(height) {
        return this.callUnary(methodDescriptorBeaconStateToGenesis, height);
    }
    /**
     * ConsensusParameters returns the beacon consensus parameters.
     */
    beaconConsensusParameters(height) {
        return this.callUnary(methodDescriptorBeaconConsensusParameters, height);
    }
    /**
     * WatchEpochs returns a channel that produces a stream of messages
     * on epoch transitions.
     *
     * Upon subscription the current epoch is sent immediately.
     */
    beaconWatchEpochs(arg) {
        return this.callServerStreaming(methodDescriptorBeaconWatchEpochs, arg);
    }
    // scheduler
    /**
     * GetValidators returns the vector of consensus validators for
     * a given epoch.
     */
    schedulerGetValidators(height) {
        return this.callUnary(methodDescriptorSchedulerGetValidators, height);
    }
    /**
     * GetCommittees returns the vector of committees for a given
     * runtime ID, at the specified block height, and optional callback
     * for querying the beacon for a given epoch/block height.
     *
     * Iff the callback is nil, `beacon.GetBlockBeacon` will be used.
     */
    schedulerGetCommittees(request) {
        return this.callUnary(methodDescriptorSchedulerGetCommittees, request);
    }
    /**
     * StateToGenesis returns the genesis state at specified block height.
     */
    schedulerStateToGenesis(height) {
        return this.callUnary(methodDescriptorSchedulerStateToGenesis, height);
    }
    /**
     * ConsensusParameters returns the scheduler consensus parameters.
     */
    schedulerConsensusParameters(height) {
        return this.callUnary(methodDescriptorSchedulerConsensusParameters, height);
    }
    /**
     * WatchCommittees returns a channel that produces a stream of
     * Committee.
     *
     * Upon subscription, all committees for the current epoch will
     * be sent immediately.
     */
    schedulerWatchCommittees() {
        return this.callServerStreaming(methodDescriptorSchedulerWatchCommittees, undefined);
    }
    // registry
    /**
     * GetEntity gets an entity by ID.
     */
    registryGetEntity(query) {
        return this.callUnary(methodDescriptorRegistryGetEntity, query);
    }
    /**
     * GetEntities gets a list of all registered entities.
     */
    registryGetEntities(height) {
        return this.callUnary(methodDescriptorRegistryGetEntities, height);
    }
    /**
     * GetNode gets a node by ID.
     */
    registryGetNode(query) {
        return this.callUnary(methodDescriptorRegistryGetNode, query);
    }
    /**
     * GetNodeByConsensusAddress looks up a node by its consensus address at the
     * specified block height. The nature and format of the consensus address depends
     * on the specific consensus backend implementation used.
     */
    registryGetNodeByConsensusAddress(query) {
        return this.callUnary(methodDescriptorRegistryGetNodeByConsensusAddress, query);
    }
    /**
     * GetNodeStatus returns a node's status.
     */
    registryGetNodeStatus(query) {
        return this.callUnary(methodDescriptorRegistryGetNodeStatus, query);
    }
    /**
     * GetNodes gets a list of all registered nodes.
     */
    registryGetNodes(height) {
        return this.callUnary(methodDescriptorRegistryGetNodes, height);
    }
    /**
     * GetRuntime gets a runtime by ID.
     */
    registryGetRuntime(query) {
        return this.callUnary(methodDescriptorRegistryGetRuntime, query);
    }
    /**
     * GetRuntimes returns the registered Runtimes at the specified
     * block height.
     */
    registryGetRuntimes(query) {
        return this.callUnary(methodDescriptorRegistryGetRuntimes, query);
    }
    /**
     * StateToGenesis returns the genesis state at specified block height.
     */
    registryStateToGenesis(height) {
        return this.callUnary(methodDescriptorRegistryStateToGenesis, height);
    }
    /**
     * GetEvents returns the events at specified block height.
     */
    registryGetEvents(height) {
        return this.callUnary(methodDescriptorRegistryGetEvents, height);
    }
    /**
     * WatchEntities returns a channel that produces a stream of
     * EntityEvent on entity registration changes.
     */
    registryWatchEntities() {
        return this.callServerStreaming(methodDescriptorRegistryWatchEntities, undefined);
    }
    /**
     * WatchNodes returns a channel that produces a stream of
     * NodeEvent on node registration changes.
     */
    registryWatchNodes() {
        return this.callServerStreaming(methodDescriptorRegistryWatchNodes, undefined);
    }
    /**
     * WatchNodeList returns a channel that produces a stream of NodeList.
     * Upon subscription, the node list for the current epoch will be sent
     * immediately.
     *
     * Each node list will be sorted by node ID in lexicographically ascending
     * order.
     */
    registryWatchNodeList() {
        return this.callServerStreaming(methodDescriptorRegistryWatchNodeList, undefined);
    }
    /**
     * WatchRuntimes returns a stream of Runtime.  Upon subscription,
     * all runtimes will be sent immediately.
     */
    registryWatchRuntimes() {
        return this.callServerStreaming(methodDescriptorRegistryWatchRuntimes, undefined);
    }
    // staking
    /**
     * TokenSymbol returns the token's ticker symbol.
     */
    stakingTokenSymbol() {
        return this.callUnary(methodDescriptorStakingTokenSymbol, undefined);
    }
    /**
     * TokenValueExponent is the token's value base-10 exponent, i.e.
     * 1 token = 10**TokenValueExponent base units.
     */
    stakingTokenValueExponent() {
        return this.callUnary(methodDescriptorStakingTokenValueExponent, undefined);
    }
    /**
     * TotalSupply returns the total number of base units.
     */
    stakingTotalSupply(height) {
        return this.callUnary(methodDescriptorStakingTotalSupply, height);
    }
    /**
     * CommonPool returns the common pool balance.
     */
    stakingCommonPool(height) {
        return this.callUnary(methodDescriptorStakingCommonPool, height);
    }
    /**
     * LastBlockFees returns the collected fees for previous block.
     */
    stakingLastBlockFees(height) {
        return this.callUnary(methodDescriptorStakingLastBlockFees, height);
    }
    /**
     * GovernanceDeposits returns the governance deposits account balance.
     */
    stakingGovernanceDeposits(height) {
        return this.callUnary(methodDescriptorStakingGovernanceDeposits, height);
    }
    /**
     * Threshold returns the specific staking threshold by kind.
     */
    stakingThreshold(query) {
        return this.callUnary(methodDescriptorStakingThreshold, query);
    }
    /**
     * Addresses returns the addresses of all accounts with a non-zero general
     * or escrow balance.
     */
    stakingAddresses(height) {
        return this.callUnary(methodDescriptorStakingAddresses, height);
    }
    /**
     * Account returns the account descriptor for the given account.
     */
    stakingAccount(query) {
        return this.callUnary(methodDescriptorStakingAccount, query);
    }
    /**
     * DelegationsFor returns the list of (outgoing) delegations for the given
     * owner (delegator).
     */
    stakingDelegationsFor(query) {
        return this.callUnary(methodDescriptorStakingDelegationsFor, query);
    }
    /**
     * DelegationsInfosFor returns (outgoing) delegations with additional
     * information for the given owner (delegator).
     */
    stakingDelegationInfosFor(query) {
        return this.callUnary(methodDescriptorStakingDelegationInfosFor, query);
    }
    /**
     * DelegationsTo returns the list of (incoming) delegations to the given
     * account.
     */
    stakingDelegationsTo(query) {
        return this.callUnary(methodDescriptorStakingDelegationsTo, query);
    }
    /**
     * DebondingDelegationsFor returns the list of (outgoing) debonding
     * delegations for the given owner (delegator).
     */
    stakingDebondingDelegationsFor(query) {
        return this.callUnary(methodDescriptorStakingDebondingDelegationsFor, query);
    }
    /**
     * DebondingDelegationsInfosFor returns (outgoing) debonding delegations
     * with additional information for the given owner (delegator).
     */
    stakingDebondingDelegationInfosFor(query) {
        return this.callUnary(methodDescriptorStakingDebondingDelegationInfosFor, query);
    }
    /**
     * DebondingDelegationsTo returns the list of (incoming) debonding
     * delegations to the given account.
     */
    stakingDebondingDelegationsTo(query) {
        return this.callUnary(methodDescriptorStakingDebondingDelegationsTo, query);
    }
    /**
     * Allowance looks up the allowance for the given owner/beneficiary combination.
     */
    stakingAllowance(query) {
        return this.callUnary(methodDescriptorStakingAllowance, query);
    }
    /**
     * StateToGenesis returns the genesis state at specified block height.
     */
    stakingStateToGenesis(height) {
        return this.callUnary(methodDescriptorStakingStateToGenesis, height);
    }
    /**
     * ConsensusParameters returns the staking consensus parameters.
     */
    stakingConsensusParameters(height) {
        return this.callUnary(methodDescriptorStakingConsensusParameters, height);
    }
    /**
     * GetEvents returns the events at specified block height.
     */
    stakingGetEvents(height) {
        return this.callUnary(methodDescriptorStakingGetEvents, height);
    }
    /**
     * WatchEvents returns a channel that produces a stream of Events.
     */
    stakingWatchEvents() {
        return this.callServerStreaming(methodDescriptorStakingWatchEvents, undefined);
    }
    // keymanager
    /**
     * GetStatus returns a key manager status by key manager ID.
     */
    keyManagerGetStatus(query) {
        return this.callUnary(methodDescriptorKeyManagerGetStatus, query);
    }
    /**
     * GetStatuses returns all currently tracked key manager statuses.
     */
    keyManagerGetStatuses(height) {
        return this.callUnary(methodDescriptorKeyManagerGetStatuses, height);
    }
    // roothash
    rootHashGetGenesisBlock(request) {
        return this.callUnary(methodDescriptorRootHashGetGenesisBlock, request);
    }
    rootHashGetLatestBlock(request) {
        return this.callUnary(methodDescriptorRootHashGetLatestBlock, request);
    }
    rootHashGetRuntimeState(request) {
        return this.callUnary(methodDescriptorRootHashGetRuntimeState, request);
    }
    rootHashGetLastRoundResults(request) {
        return this.callUnary(methodDescriptorRootHashGetLastRoundResults, request);
    }
    rootHashGetIncomingMessageQueueMeta(request) {
        return this.callUnary(methodDescriptorRootHashGetIncomingMessageQueueMeta, request);
    }
    rootHashGetIncomingMessageQueue(request) {
        return this.callUnary(methodDescriptorRootHashGetIncomingMessageQueue, request);
    }
    rootHashStateToGenesis(height) {
        return this.callUnary(methodDescriptorRootHashStateToGenesis, height);
    }
    rootHashConsensusParameters(height) {
        return this.callUnary(methodDescriptorRootHashConsensusParameters, height);
    }
    rootHashGetEvents(height) {
        return this.callUnary(methodDescriptorRootHashGetEvents, height);
    }
    rootHashWatchBlocks(runtimeID) {
        return this.callServerStreaming(methodDescriptorRootHashWatchBlocks, runtimeID);
    }
    rootHashWatchEvents(runtimeID) {
        return this.callServerStreaming(methodDescriptorRootHashWatchEvents, runtimeID);
    }
    // governance
    /**
     * ActiveProposals returns a list of all proposals that have not yet closed.
     */
    governanceActiveProposals(height) {
        return this.callUnary(methodDescriptorGovernanceActiveProposals, height);
    }
    /**
     * Proposals returns a list of all proposals.
     */
    governanceProposals(height) {
        return this.callUnary(methodDescriptorGovernanceProposals, height);
    }
    /**
     * Proposal looks up a specific proposal.
     */
    governanceProposal(request) {
        return this.callUnary(methodDescriptorGovernanceProposal, request);
    }
    /**
     * Votes looks up votes for a specific proposal.
     */
    governanceVotes(request) {
        return this.callUnary(methodDescriptorGovernanceVotes, request);
    }
    /**
     * PendingUpgrades returns a list of all pending upgrades.
     */
    governancePendingUpgrades(height) {
        return this.callUnary(methodDescriptorGovernancePendingUpgrades, height);
    }
    /**
     * StateToGenesis returns the genesis state at specified block height.
     */
    governanceStateToGenesis(height) {
        return this.callUnary(methodDescriptorGovernanceStateToGenesis, height);
    }
    /**
     * ConsensusParameters returns the governance consensus parameters.
     */
    governanceConsensusParameters(height) {
        return this.callUnary(methodDescriptorGovernanceConsensusParameters, height);
    }
    /**
     * GetEvents returns the events at specified block height.
     */
    governanceGetEvents(height) {
        return this.callUnary(methodDescriptorGovernanceGetEvents, height);
    }
    /**
     * WatchEvents returns a channel that produces a stream of Events.
     */
    governanceWatchEvents() {
        return this.callServerStreaming(methodDescriptorGovernanceWatchEvents, undefined);
    }
    // storage
    /**
     * SyncGet fetches a single key and returns the corresponding proof.
     */
    storageSyncGet(request) {
        return this.callUnary(methodDescriptorStorageSyncGet, request);
    }
    /**
     * SyncGetPrefixes fetches all keys under the given prefixes and returns
     * the corresponding proofs.
     */
    storageSyncGetPrefixes(request) {
        return this.callUnary(methodDescriptorStorageSyncGetPrefixes, request);
    }
    /**
     * SyncIterate seeks to a given key and then fetches the specified
     * number of following items based on key iteration order.
     */
    storageSyncIterate(request) {
        return this.callUnary(methodDescriptorStorageSyncIterate, request);
    }
    /**
     * GetCheckpoints returns a list of checkpoint metadata for all known checkpoints.
     */
    storageGetCheckpoints(request) {
        return this.callUnary(methodDescriptorStorageGetCheckpoints, request);
    }
    /**
     * GetDiff returns an iterator of write log entries that must be applied
     * to get from the first given root to the second one.
     */
    storageGetDiff(request) {
        return this.callServerStreaming(methodDescriptorStorageGetDiff, request);
    }
    /**
     * GetCheckpointChunk fetches a specific chunk from an existing chekpoint.
     */
    storageGetCheckpointChunk(chunk) {
        return this.callServerStreaming(methodDescriptorStorageGetCheckpointChunk, chunk);
    }
    // worker/storage
    /**
     * GetLastSyncedRound retrieves the last synced round for the storage worker.
     */
    storageWorkerGetLastSyncedRound(request) {
        return this.callUnary(methodDescriptorStorageWorkerGetLastSyncedRound, request);
    }
    /**
     * WaitForRound waits until the storage worker syncs the given round or root.
     * It returns the round synced to; this will typically equal the given root's
     * round, but may be higher.
     */
    storageWorkerWaitForRound(request) {
        return this.callUnary(methodDescriptorStorageWorkerWaitForRound, request);
    }
    /**
     * PauseCheckpointer pauses or unpauses the storage worker's checkpointer.
     */
    storageWorkerPauseCheckpointer(request) {
        return this.callUnary(methodDescriptorStorageWorkerPauseCheckpointer, request);
    }
    // runtime/client
    /**
     * SubmitTx submits a transaction to the runtime transaction scheduler and waits
     * for transaction execution results.
     */
    runtimeClientSubmitTx(request) {
        return this.callUnary(methodDescriptorRuntimeClientSubmitTx, request);
    }
    /**
     * SubmitTxMeta submits a transaction to the runtime transaction scheduler and waits for
     * transaction execution results.
     *
     * Response includes transaction metadata - e.g. round at which the transaction was included
     * in a block.
     */
    runtimeClientSubmitTxMeta(request) {
        return this.callUnary(methodDescriptorRuntimeClientSubmitTxMeta, request);
    }
    /**
     * SubmitTxNoWait submits a transaction to the runtime transaction scheduler but does
     * not wait for transaction execution.
     */
    runtimeClientSubmitTxNoWait(request) {
        return this.callUnary(methodDescriptorRuntimeClientSubmitTxNoWait, request);
    }
    /**
     * CheckTx asks the local runtime to check the specified transaction.
     */
    runtimeClientCheckTx(request) {
        return this.callUnary(methodDescriptorRuntimeClientCheckTx, request);
    }
    /**
     * GetGenesisBlock returns the genesis block.
     */
    runtimeClientGetGenesisBlock(runtimeID) {
        return this.callUnary(methodDescriptorRuntimeClientGetGenesisBlock, runtimeID);
    }
    /**
     * GetBlock fetches the given runtime block.
     */
    runtimeClientGetBlock(request) {
        return this.callUnary(methodDescriptorRuntimeClientGetBlock, request);
    }
    /**
     * GetLastRetainedBlock returns the last retained block.
     */
    runtimeClientGetLastRetainedBlock(runtimeID) {
        return this.callUnary(methodDescriptorRuntimeClientGetLastRetainedBlock, runtimeID);
    }
    /**
     * GetTransactions fetches all runtime transactions in a given block.
     */
    runtimeClientGetTransactions(request) {
        return this.callUnary(methodDescriptorRuntimeClientGetTransactions, request);
    }
    /**
     * GetTransactionsWithResults fetches all runtime transactions in a given block together with
     * its results (outputs and emitted events).
     */
    runtimeClientGetTransactionsWithResults(request) {
        return this.callUnary(methodDescriptorRuntimeClientGetTransactionsWithResults, request);
    }
    /**
     * GetEvents returns all events emitted in a given block.
     */
    runtimeClientGetEvents(request) {
        return this.callUnary(methodDescriptorRuntimeClientGetEvents, request);
    }
    /**
     * Query makes a runtime-specific query.
     */
    runtimeClientQuery(request) {
        return this.callUnary(methodDescriptorRuntimeClientQuery, request);
    }
    /**
     * WatchBlocks subscribes to blocks for a specific runtimes.
     */
    runtimeClientWatchBlocks(runtimeID) {
        return this.callServerStreaming(methodDescriptorRuntimeClientWatchBlocks, runtimeID);
    }
    // consensus
    /**
     * SubmitTx submits a signed consensus transaction and waits for the transaction to be included
     * in a block. Use SubmitTxNoWait if you only need to broadcast the transaction.
     */
    consensusSubmitTx(tx) {
        return this.callUnary(methodDescriptorConsensusSubmitTx, tx);
    }
    /**
     * StateToGenesis returns the genesis state at the specified block height.
     */
    consensusStateToGenesis(height) {
        return this.callUnary(methodDescriptorConsensusStateToGenesis, height);
    }
    /**
     * EstimateGas calculates the amount of gas required to execute the given transaction.
     */
    consensusEstimateGas(req) {
        return this.callUnary(methodDescriptorConsensusEstimateGas, req);
    }
    /**
     * GetSignerNonce returns the nonce that should be used by the given
     * signer for transmitting the next transaction.
     */
    consensusGetSignerNonce(req) {
        return this.callUnary(methodDescriptorConsensusGetSignerNonce, req);
    }
    /**
     * GetBlock returns a consensus block at a specific height.
     */
    consensusGetBlock(height) {
        return this.callUnary(methodDescriptorConsensusGetBlock, height);
    }
    /**
     * GetTransactions returns a list of all transactions contained within a
     * consensus block at a specific height.
     *
     * NOTE: Any of these transactions could be invalid.
     */
    consensusGetTransactions(height) {
        return this.callUnary(methodDescriptorConsensusGetTransactions, height);
    }
    /**
     * GetTransactionsWithResults returns a list of transactions and their
     * execution results, contained within a consensus block at a specific
     * height.
     */
    consensusGetTransactionsWithResults(height) {
        return this.callUnary(methodDescriptorConsensusGetTransactionsWithResults, height);
    }
    /**
     * GetUnconfirmedTransactions returns a list of transactions currently in the local node's
     * mempool. These have not yet been included in a block.
     */
    consensusGetUnconfirmedTransactions() {
        return this.callUnary(methodDescriptorConsensusGetUnconfirmedTransactions, undefined);
    }
    /**
     * GetGenesisDocument returns the original genesis document.
     */
    consensusGetGenesisDocument() {
        return this.callUnary(methodDescriptorConsensusGetGenesisDocument, undefined);
    }
    /**
     * GetChainContext returns the chain domain separation context.
     */
    consensusGetChainContext() {
        return this.callUnary(methodDescriptorConsensusGetChainContext, undefined);
    }
    /**
     * GetStatus returns the current status overview.
     */
    consensusGetStatus() {
        return this.callUnary(methodDescriptorConsensusGetStatus, undefined);
    }
    /**
     * GetNextBlockState returns the state of the next block being voted on by validators.
     */
    consensusGetNextBlockState() {
        return this.callUnary(methodDescriptorConsensusGetNextBlockState, undefined);
    }
    /**
     * WatchBlocks returns a channel that produces a stream of consensus
     * blocks as they are being finalized.
     */
    consensusWatchBlocks() {
        return this.callServerStreaming(methodDescriptorConsensusWatchBlocks, undefined);
    }
    /**
     * GetLightBlock returns a light version of the consensus layer block that can be used for light
     * client verification.
     */
    consensusLightGetLightBlock(height) {
        return this.callUnary(methodDescriptorConsensusLightGetLightBlock, height);
    }
    /**
     * GetParameters returns the consensus parameters for a specific height.
     */
    consensusLightGetParameters(height) {
        return this.callUnary(methodDescriptorConsensusLightGetParameters, height);
    }
    /**
     * SyncGet fetches a single key and returns the corresponding proof.
     */
    consensusLightStateSyncGet(request) {
        return this.callUnary(methodDescriptorConsensusLightStateSyncGet, request);
    }
    /**
     * SyncGetPrefixes fetches all keys under the given prefixes and returns
     * the corresponding proofs.
     */
    consensusLightStateSyncGetPrefixes(request) {
        return this.callUnary(methodDescriptorConsensusLightStateSyncGetPrefixes, request);
    }
    /**
     * SyncIterate seeks to a given key and then fetches the specified
     * number of following items based on key iteration order.
     */
    consensusLightStateSyncIterate(request) {
        return this.callUnary(methodDescriptorConsensusLightStateSyncIterate, request);
    }
    /**
     * SubmitTxNoWait submits a signed consensus transaction, but does not wait for the transaction
     * to be included in a block. Use SubmitTx if you need to wait for execution.
     */
    consensusLightSubmitTxNoWait(tx) {
        return this.callUnary(methodDescriptorConsensusLightSubmitTxNoWait, tx);
    }
    /**
     * SubmitEvidence submits evidence of misbehavior.
     */
    consensusLightSubmitEvidence(evidence) {
        return this.callUnary(methodDescriptorConsensusLightSubmitEvidence, evidence);
    }
    // control
    /**
     * RequestShutdown requests the node to shut down gracefully.
     *
     * If the wait argument is true then the method will also wait for the
     * shutdown to complete.
     */
    nodeControllerRequestShudown() {
        return this.callUnary(methodDescriptorNodeControllerRequestShutdown, undefined);
    }
    /**
     * WaitSync waits for the node to finish syncing.
     */
    nodeControllerWaitSync() {
        return this.callUnary(methodDescriptorNodeControllerWaitSync, undefined);
    }
    /**
     * IsSynced checks whether the node has finished syncing.
     */
    nodeControllerIsSynced() {
        return this.callUnary(methodDescriptorNodeControllerIsSynced, undefined);
    }
    /**
     * WaitReady waits for the node to accept runtime work.
     */
    nodeControllerWaitReady() {
        return this.callUnary(methodDescriptorNodeControllerWaitReady, undefined);
    }
    /**
     * IsReady checks whether the node is ready to accept runtime work.
     */
    nodeControllerIsReady() {
        return this.callUnary(methodDescriptorNodeControllerIsReady, undefined);
    }
    /**
     * UpgradeBinary submits an upgrade descriptor to a running node.
     * The node will wait for the appropriate epoch, then update its binaries
     * and shut down.
     */
    nodeControllerUpgradeBinary(descriptor) {
        return this.callUnary(methodDescriptorNodeControllerUpgradeBinary, descriptor);
    }
    /**
     * CancelUpgrade cancels the specific pending upgrade, unless it is already in progress.
     */
    nodeControllerCancelUpgrade(descriptor) {
        return this.callUnary(methodDescriptorNodeControllerCancelUpgrade, descriptor);
    }
    /**
     * GetStatus returns the current status overview of the node.
     */
    nodeControllerGetStatus() {
        return this.callUnary(methodDescriptorNodeControllerGetStatus, undefined);
    }
    /**
     * SetEpoch manually sets the current epoch to the given epoch.
     *
     * NOTE: This only works with a mock beacon backend and will otherwise
     *       return an error.
     */
    debugControllerSetEpoch(epoch) {
        return this.callUnary(methodDescriptorDebugControllerSetEpoch, epoch);
    }
    /**
     * WaitNodesRegistered waits for the given number of nodes to register.
     */
    debugControllerWaitNodesRegistered(count) {
        return this.callUnary(methodDescriptorDebugControllerWaitNodesRegistered, count);
    }
}
exports.NodeInternal = NodeInternal;
