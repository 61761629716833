"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boxOpen = exports.boxSeal = exports.deriveSymmetricKey = void 0;
// @ts-expect-error missing declaration
const deoxysii = require("deoxysii");
const js_sha512_1 = require("js-sha512");
const nacl = require("tweetnacl");
const BOX_KDF_TWEAK = 'MRAE_Box_Deoxys-II-256-128';
/**
 * deriveSymmetricKey derives a MRAE AEAD symmetric key suitable for use with the asymmetric
 * box primitives from the provided X25519 public and private keys.
 */
function deriveSymmetricKey(publicKey, privateKey) {
    const pmk = nacl.scalarMult(privateKey, publicKey);
    var kdf = js_sha512_1.sha512_256.hmac.create(BOX_KDF_TWEAK);
    kdf.update(pmk);
    return new Uint8Array(kdf.arrayBuffer());
}
exports.deriveSymmetricKey = deriveSymmetricKey;
/**
 * boxSeal boxes ("seals") the provided additional data and plaintext via
 * Deoxys-II-256-128 using a symmetric key derived from the provided
 * X25519 public and private keys.
 */
function boxSeal(nonce, plainText, associateData, publicKey, privateKey) {
    const sharedKey = deriveSymmetricKey(publicKey, privateKey);
    var aead = new deoxysii.AEAD(sharedKey);
    return aead.encrypt(nonce, plainText, associateData);
}
exports.boxSeal = boxSeal;
/**
 * boxOpen unboxes ("opens") the provided additional data and plaintext via
 * Deoxys-II-256-128 using a symmetric key derived from the provided
 * X25519 public and private keys.
 */
function boxOpen(nonce, ciperText, associateData, publicKey, privateKey) {
    const sharedKey = deriveSymmetricKey(publicKey, privateKey);
    var aead = new deoxysii.AEAD(sharedKey);
    return aead.decrypt(nonce, ciperText, associateData);
}
exports.boxOpen = boxOpen;
