"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EllipticSigner = exports.BlindContextSigner = exports.verify = void 0;
const oasis = require("@oasisprotocol/client");
const elliptic = require("elliptic");
const SECP256K1 = new elliptic.ec('secp256k1');
async function verify(context, message, signature, publicKey) {
    const signerMessage = await oasis.signature.prepareSignerMessage(context, message);
    const signerMessageA = Array.from(signerMessage);
    const signatureA = Array.from(signature);
    const publicKeyA = Array.from(publicKey);
    // @ts-expect-error acceptance of array-like types is not modeled
    return SECP256K1.verify(signerMessageA, signatureA, publicKeyA);
}
exports.verify = verify;
class BlindContextSigner {
    constructor(signer) {
        this.signer = signer;
    }
    public() {
        return this.signer.public();
    }
    async sign(context, message) {
        const signerMessage = await oasis.signature.prepareSignerMessage(context, message);
        return await this.signer.sign(signerMessage);
    }
}
exports.BlindContextSigner = BlindContextSigner;
class EllipticSigner {
    constructor(key, note) {
        if (note !== 'this key is not important')
            throw new Error('insecure signer implementation');
        this.key = key;
    }
    static fromRandom(note) {
        return new EllipticSigner(SECP256K1.genKeyPair(), note);
    }
    static fromPrivate(priv, note) {
        return new EllipticSigner(SECP256K1.keyFromPrivate(Array.from(priv)), note);
    }
    public() {
        return new Uint8Array(this.key.getPublic(true, 'array'));
    }
    async sign(message) {
        const sig = this.key.sign(Array.from(message), { canonical: true });
        return new Uint8Array(sig.toDER());
    }
}
exports.EllipticSigner = EllipticSigner;
