"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromBech32 = exports.toBech32 = exports.fromMultisigConfig = exports.fromSigspec = exports.V0_MULTISIG_CONTEXT_VERSION = exports.V0_MULTISIG_CONTEXT_IDENTIFIER = exports.V0_SECP256K1ETH_CONTEXT_VERSION = exports.V0_SECP256K1ETH_CONTEXT_IDENTIFIER = void 0;
const oasis = require("@oasisprotocol/client");
const elliptic = require("elliptic");
const sha3_1 = require("sha3");
exports.V0_SECP256K1ETH_CONTEXT_IDENTIFIER = 'oasis-runtime-sdk/address: secp256k1eth';
exports.V0_SECP256K1ETH_CONTEXT_VERSION = 0;
exports.V0_MULTISIG_CONTEXT_IDENTIFIER = 'oasis-runtime-sdk/address: multisig';
exports.V0_MULTISIG_CONTEXT_VERSION = 0;
const SECP256K1 = new elliptic.ec('secp256k1');
async function fromSigspec(spec) {
    if (spec.ed25519) {
        return await oasis.staking.addressFromPublicKey(spec.ed25519);
    }
    else if (spec.secp256k1eth) {
        // Use a scheme such that we can compute Secp256k1 addresses from Ethereum
        // addresses as this makes things more interoperable.
        const untaggedPk = SECP256K1.keyFromPublic(Array.from(spec.secp256k1eth))
            .getPublic(false, 'array')
            .slice(1);
        const hash = new sha3_1.Keccak(256);
        hash.update(Buffer.from(untaggedPk));
        const pkData = hash.digest().slice(32 - 20);
        return await oasis.address.fromData(exports.V0_SECP256K1ETH_CONTEXT_IDENTIFIER, exports.V0_SECP256K1ETH_CONTEXT_VERSION, pkData);
    }
    else {
        throw new Error('unsupported signature address specification type');
    }
}
exports.fromSigspec = fromSigspec;
async function fromMultisigConfig(config) {
    const configU8 = oasis.misc.toCBOR(config);
    return await oasis.address.fromData(exports.V0_MULTISIG_CONTEXT_IDENTIFIER, exports.V0_MULTISIG_CONTEXT_VERSION, configU8);
}
exports.fromMultisigConfig = fromMultisigConfig;
function toBech32(addr) {
    return oasis.staking.addressToBech32(addr);
}
exports.toBech32 = toBech32;
function fromBech32(str) {
    return oasis.staking.addressFromBech32(str);
}
exports.fromBech32 = fromBech32;
